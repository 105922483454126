import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from "react-dropzone";
import AdminBanner from 'components/Banner/AdminBanner';
import { scroller } from "react-scroll";
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function EditElibraryCategory(props) {
    //console.log("Edit ID = "+props.location.state.id);
    const currentUser = authenticationService.currentUserValue;
    const [nameth, setNameTH] = useState('');
    const [nameen, setNameEN] = useState('');
    const [status, setStatus] = useState(-1);
    const [buttonIcon, setButtonIcon] = useState({});
    const [currentLevel, setCurrentLevel] = useState(0);
    const [parentCatID, setParentCatID] = useState(0);
    const [parentCategory, setParentCategory] = useState([]);
    const [errors, setError] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getelibraryCatParent', { params: { id: props.location.state.id } })
            .then((response) => {
                setParentCategory(response.data);
            })
        axios.get(process.env.REACT_APP_APIURL + '/api-web/elibraryCatbyID', { params: { id: props.location.state.id } })
            .then((response) => {
                setNameTH(response.data[0].CategoriesNameTH);
                setNameEN(response.data[0].CategoriesNameEN);
                setParentCatID(response.data[0].ParentBookCatID);
                setCurrentLevel(response.data[0].Level);
                setStatus(response.data[0].Status);
                const iconImageFile = {
                    file: null,
                    name: null,
                    preview: response.data[0].ButtonIcon,
                    size: null
                };
                setButtonIcon(iconImageFile);
            })
    }, []); /* <-- add this for run once*/

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (nameth.trim().length === 0) {
            formIsValid = false;
            serrors["nameth"] = "กรุณาระบุชื่อภาษาไทย";
        }
        /*if (nameen.trim().length === 0) {
            formIsValid = false;
            serrors["nameen"] = "กรุณาระบุชื่อภาษาอังกฤษ";
        }*/
        if (Number(currentLevel) === 0) {
            if (buttonIcon.preview === null) {
                formIsValid = false;
                serrors["buttonIcon"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับเว็บ";
            }
        }
        if (status === -1) {
            formIsValid = false;
            serrors["status"] = "กรุณาเลือกสถานะ";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        setError(serrors);
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", props.location.state.id);
            formData.append("titleth", nameth);
            formData.append("titleen", nameen);
            formData.append("status", status);
            formData.append("level", currentLevel);
            formData.append("parentID", parentCatID);
            formData.append("updateby", currentUser.UserID);
            formData.append("iconfile", buttonIcon.file);
            axios.put(process.env.REACT_APP_APIURL + '/api-web/updateElibraryCat', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/elibrary/ElibraryCategory" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const WebIconButtonPreview = (
        (buttonIcon.preview !== null) && (<img className="preview-upload" src={buttonIcon.preview} onChange={(e) => { e.target.src = URL.createObjectURL(buttonIcon); }} alt="Web Cover" />)
    );
    const handleIconButtonOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setButtonIcon(imageFile);
    };
    function changeLevel(id) {
        const result = parentCategory.find((item) => {
            return Number(item.BookCatID) === Number(id)
        })
        if (typeof result !== 'undefined') {
            setCurrentLevel(Number(result.Level) + 1);
        }
        else {
            setCurrentLevel(0);
        }
        setParentCatID(Number(id));
    }
    function ShowLevel(level) {
        let str = "";
        for (let i = 0; i < level * 3; i++) {
            str += "-";
        }
        return str;
    }
    const ParentCatSelect = parentCategory.map((bookCatID, index) => (
        <option
            key={index}
            value={bookCatID.BookCatID}
        >
            {ShowLevel(bookCatID.Level) + " " + bookCatID.CategoriesNameTH}
        </option>
    ));
    const cancel = () => {
        props.history.push({ pathname: "/admin/elibrary/ElibraryCategory" });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการคลังความรู้ด้านสิทธิมนุษยชน"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการคลังความรู้ด้านสิทธิมนุษยชน</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/elibrary/ElibraryCategory" }}>{"จัดการหมวดหมู่"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/activity/EditElibraryCategory" }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="form-group">
                                    <label htmlFor="nameth" className="required">ชื่อหมวดหมู่ (ภาษาไทย)</label>
                                    <input type="text" className="form-control" id="nameth" name="nameth" placeholder="กรุณากรอก" value={nameth} onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                    <SError error={errors["nameth"]} />
                                </div>


                            </div>
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="form-group">
                                    <label htmlFor="nameen">ชื่อหมวดหมู่ (ภาษาอังกฤษ)</label>
                                    <input type="text" className="form-control" id="nameen" name="nameen" placeholder="กรุณากรอก" value={nameen} onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                    <SError error={errors["nameen"]} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="tab-content pl-2 pr-2" >
                            <div className="col-6">
                                <label htmlFor="parentCatID" className="required">ภายใต้</label>
                                <select className="form-control mb-2 mr-2" id="parentCatID" name="bookCatID" value={parentCatID} onChange={(e) => { changeLevel(e.currentTarget.value); }}>
                                    <option key="0" value="0" > ไม่อยู่ภายใต้</option>
                                    {ParentCatSelect}
                                </select>
                                <SError error={errors["parentCatID"]} />
                            </div>
                            {(currentLevel === 0) && (
                                <div className="col-12">
                                    <label className="required">รูปภาพไอคอนสำหรับแสดงปุ่ม</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleIconButtonOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {WebIconButtonPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["buttonIcon"]}</span>
                                </div>)
                            }
                        </div>

                        <div className="tab-content pl-2 pr-2" >
                            <div className="col-12">
                                <label htmlFor="published1" className="required mr-2">สถานะ</label>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="status" id="published1" value="1" checked={status === true} onChange={(e) => { setStatus(true); }} />
                                    <label className="form-check-label" htmlFor="published1">เผยแพร่</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="status" id="published2" value="0" checked={status === false} onChange={(e) => { setStatus(false); }} />
                                    <label className="form-check-label" htmlFor="published2">ไม่เผยแพร่</label>
                                </div>
                                <SError error={errors["status"]} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
