import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authenticationService } from "services/authentication.service.js";
import Navbar from "../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import idcardico from "assets/images/icon/idcard.svg";
import passportico from "assets/images/icon/passport.svg";
import emailico from "assets/images/icon/email.svg";
import banner from "assets/images/banner/advertise.svg";
import noimage from 'assets/images/no_image.svg';
import {
  useMutation,
  //useQuery,
  gql,
} from "@apollo/client";
import useWindowSize from "../../utils/UseWindowSize";

const USER_LOGIN = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      member {
        UserID
        GroupID
        CitizenID
        PassportNo
        FirstnameTH
        LastnameTH
        FirstnameEN
        LastnameEN
        MemberType
        Avatar
        LastToken
      }
    }
  }
`;
const swal = withReactContent(Swal);
export default function Login(props) {
  let language = localStorage.getItem("language");
  const [width] = useWindowSize();
  //const dispatch = useDispatch();
  //const user = JSON.parse(localStorage.getItem('user'));
  const [fields, setFields] = useState({});
  const [thaiID, setThaiID] = useState("");
  const [thaiIDPassword, setThaiIDPassword] = useState("");
  const [passportID, setPassportID] = useState("");
  const [passportPassword, setPassportPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setError] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const [popupList, setPopupList] = useState([]);
  const [popupIndex, setPopupIndex] = useState(0);
  const [imgWebCover, setIMGWebCover] = useState([]);
  const [thname, setNameTH] = useState('');
  const [thcontent, setTHContent] = useState("");
  const [enname, setNameEN] = useState('');
  const [encontent, setENContent] = useState("");
  const [urlLink, setURLLink] = useState('');

  const idCardLoginButton = React.createRef();
  const passportLoginButton = React.createRef();
  const emailLoginButton = React.createRef();

  const handleChange = (e) => {
    if (e.key === 'Enter') {
      //console.log('enter press here! ')
    }
    else {
      let sfields = fields;
      sfields[e.target.name] = e.target.value;
      setFields(sfields);
    }
  };
  useEffect(() => {
    axios.get(process.env.REACT_APP_APIURL + '/api-web/getPopupPublic', { params: { popupType: 2 } }).then((response) => {
      setPopupList(response.data);
      //console.log(response.data)
      if(response.data.length > 0){
        let data = response.data[0];
        setNameTH(data.TitleTH);
        setTHContent(data.DescriptionTH);
        setNameEN(data.TitleEN);
        setENContent(data.DescriptionEN);
        setURLLink(data.URL);
        if (data.ImageWeb !== null) {
          if (data.ImageWeb.trim().length > 0) {
            const webimageFile = {
              file: null,
              name: null,
              preview: data.ImageWeb,
              size: null
            };
            setIMGWebCover(webimageFile);
          }
          else {
            const webimageFile = {
              file: null,
              name: null,
              //preview: 'undefined',
              size: null
            };
            setIMGWebCover(webimageFile);
          }
        }

      }
    })
    $(function () {
      $("#show_hide_password a").on("click", function (event) {
        //alert('click');
        event.preventDefault();
        if ($("#show_hide_password input").attr("type") === "text") {
          $("#show_hide_password input").attr("type", "password");
          $("#show_hide_password i").addClass("fa-eye-slash");
          $("#show_hide_password i").removeClass("fa-eye");
        } else if ($("#show_hide_password input").attr("type") === "password") {
          $("#show_hide_password input").attr("type", "text");
          $("#show_hide_password i").removeClass("fa-eye-slash");
          $("#show_hide_password i").addClass("fa-eye");
        }
      });
    });
    return () => {
      //cleanup
    };
  }, []);
  const handleValidation = (loginType) => {
    let serrors = {};
    let formIsValid = true;

    if (loginType === "THAIID") {
      if (!fields["IDCard"]) {
        formIsValid = false;
        serrors["IDCard"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["IDCard"] !== "undefined") {
        if (!isFinite(String(fields["IDCard"])))
          serrors["IDCard"] =
            "หมายเลขบัตรประชาชนต้องประกอบไปด้วยตัวเลขเท่านั้น";
        if (fields["IDCard"].length !== 13)
          serrors["IDCard"] = "กรุณากรอกหมายเลข 13 หลัก";
        /*if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["IDCard"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["IDCard"].length - lastDotPos) > 2)) {
          formIsValid = false;
          serrors["IDCard"] = "รูปแบบของหมายเลขบัตรประชาชนไม่ถูกต้อง";
        }*/
      }
      if (!fields["IDCardPassword"]) {
        formIsValid = false;
        serrors["IDCardPassword"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["IDCardPassword"] !== "undefined") {
        if (fields["IDCardPassword"].length < 4) {
          formIsValid = false;
          serrors["IDCardPassword"] = "รหัสผ่านต้องไม่น้อยกว่า 4 ตัวอักษร";
        }
      }
    } else if (loginType === "PASSPORT") {
      if (!fields["PassportID"]) {
        formIsValid = false;
        serrors["PassportID"] = "กรุณากรอกข้อมูล";
      }

      if (!fields["PassportPassword"]) {
        formIsValid = false;
        serrors["PassportPassword"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["PassportPassword"] !== "undefined") {
        if (fields["PassportPassword"].length < 4) {
          formIsValid = false;
          serrors["PassportPassword"] = "รหัสผ่านต้องไม่น้อยกว่า 4 ตัวอักษร";
        }
      }
    } else if (loginType === "EMAIL") {
      if (!fields["UserEmail"]) {
        formIsValid = false;
        serrors["UserEmail"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["UserEmail"] !== "undefined") {
        let lastAtPos = fields["UserEmail"].lastIndexOf("@");
        let lastDotPos = fields["UserEmail"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["UserEmail"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["UserEmail"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          serrors["UserEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
        }
      }
      if (!fields["EmailPassword"]) {
        formIsValid = false;
        serrors["EmailPassword"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["EmailPassword"] !== "undefined") {
        if (fields["EmailPassword"].length < 4) {
          formIsValid = false;
          serrors["EmailPassword"] = "รหัสผ่านต้องไม่น้อยกว่า 4 ตัวอักษร";
        }
      }
    }
    //Name
    /*if(!fields["thname"]){
       formIsValid = false;
       serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
    }*/

    /*if(typeof fields["thname"] !== "undefined"){
       if(!fields["thname"].match(/^[a-zA-Z]+$/)){
          formIsValid = false;
          errors["thname"] = "Only letters";
       }
    }*/
    /*if(!fields["published"]){
        formIsValid = false;
        serrors["published"] = "กรุณาเลือกสถานะ";
     }*/
    //console.log(serrors)
    setError(serrors);
    return formIsValid;
  };
const userlogin = async (loginData) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_APIURL}/api-web/login`,
            loginData
        );
        if (response.data) {
            swal
                .fire({
                    icon: "success",
                    title: "Success",
                    text: "Login Success",
                })
                .then(() => {

                    const user = JSON.stringify(response.data.member);
                    localStorage.setItem("user", user);
                    authenticationService.setuserprofile(response.data.member);
                    console.log(authenticationService);
                    const currentUser = authenticationService.currentUserValue;

                    axios
                        .get(`${process.env.REACT_APP_APIURL}/api-web/isNHRCUser`, {
                            params: { userid: currentUser.UserID },
                        })
                        .then((res) => {
                            swal.close();
                            if (res.data.status === true) {
                                setIsAdmin(true);
                                if (popupList.length > 0) {
                                    $('#ModalCarousel').modal('show');
                                } else {
                                    props.history.push("/admin");
                                    window.location.reload();
                                }
                            } else {
                                setIsAdmin(false);
                                if (popupList.length > 0) {
                                    $('#ModalCarousel').modal('show');
                                } else {
                                    props.history.push("/authen");
                                    window.location.reload();
                                }
                            }
                        })
                        .catch(() => {
                            swal.close();
                            swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "เกิดข้อผิดพลาด",
                            });
                        });
                });
        } else {
            swal.fire({
                icon: "error",
                title: "Failed",
                text: "บัตรประชาชนไม่ตรงกับบัญชีใด ๆ หรือ รหัสผ่านไม่ถูกต้อง",
            });
        }
    } catch (error) {
        swal.fire({
            icon: "error",
            title: "Failed",
            text: "บัตรประชาชนไม่ตรงกับบัญชีใด ๆ หรือ รหัสผ่านไม่ถูกต้อง",
        });
    }
};
  const register = () => {
    props.history.push("/authen/Register");
  };
  const forgotPassword = () => {
    props.history.push("/authen/ForgotPassword");
  };
  const WebCoverPreview = (
    <div className="col-12 text-center">
        <img className="preview-upload" src={typeof imgWebCover.preview === 'undefined' ? noimage : imgWebCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgWebCover); }} alt={thname} style={{height:'550px'}}/>
    </div>
);
  function selectPopup(item){
    let data = item;
    setNameTH(data.TitleTH);
    setTHContent(data.DescriptionTH);
    setURLLink(data.URL);
    if (data.ImageWeb !== null) {
      if (data.ImageWeb.trim().length > 0) {
        const webimageFile = {
          file: null,
          name: null,
          preview: data.ImageWeb,
          size: null
        };
        setIMGWebCover(webimageFile);
      }
      else {
        const webimageFile = {
          file: null,
          name: null,
          //preview: 'undefined',
          size: null
        };
        setIMGWebCover(webimageFile);
      }
    }
  }
  const popupContent = popupList.map((popupItem, index) => (
    <li key={"popup-"+index} data-target="#carousel-example-generic" data-slide-to="0" onClick={(e)=>{e.preventDefault();setPopupIndex(index);selectPopup(popupItem);}} className={Number(popupIndex) === Number(index) ? "active" : ""}></li>
  ));
  function closePopup(){
    if(isAdmin===true){
      props.history.push("/admin");
      window.location.reload();
    }
    else{
      props.history.push("/authen");
      window.location.reload();
    }
  }
  return (
    <>
      {width > 991 && (
        <div id="header-wrapper">
          {/*{<Navbar />}*/}
          <ContentBanner
            language={language}
            theader={"เข้าสู่ระบบ"}
            eheader={"Login / Register"}
            bannerkey="LOGIN"
            banner={banner}
            path={
              <>
                <span>/</span>
                <Link
                  id="profile-btn"
                  className="active"
                  to={{
                    pathname: "/authen/Login",
                    state: {language: language},
                  }}
                >
                  {language === "Thai" ? "เข้าสู่ระบบ" : "Login"}
                </Link>
              </>
            }
          />
        </div>
      )}
      <main className="content-page login-bg">
        <div className="container">
          <ul
            id="icon-tab"
            className="nav nav-pills mb-3 mx-auto justify-content-center list_respon"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                <div>
                  <div className="bg-orang icon-circle">
                    <img src={idcardico} alt="ID Card" />
                  </div>
                  <span
                    className="button-tab"
                    onClick={(e) => {
                      idCardLoginButton.current.focus();
                    }}
                  >
                    {language === "Thai" ? "บัตรประชาชน" : "ID Card"}
                  </span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="bg-orang icon-circle">
                    <img src={passportico} alt="ID Card" />
                  </div>
                  <span
                    className="button-tab"
                    onClick={(e) => {
                      passportLoginButton.current.focus();
                    }}
                  >
                    {language === "Thai" ? "หนังสือเดินทาง" : "Passport"}
                  </span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="bg-orang icon-circle">
                    <img src={emailico} alt="ID Card" />
                  </div>
                  <span
                    className="button-tab"
                    onClick={(e) => {
                      emailLoginButton.current.focus();
                    }}
                  >
                    {language === "Thai" ? "อีเมล์" : "Email"}
                  </span>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="mhk-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="formContent box_login">
                <h1 className="title-header">
                  <span className="orang underline">
                    {language === "Thai" ? "เข้าสู่ระบบ" : "Login"}
                  </span>
                </h1>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (handleValidation("THAIID")) {
                      userlogin({
                        variables: {
                          data: {
                            loginType: "THAIID",
                            username: thaiID,
                            password: thaiIDPassword,
                          },
                        },
                      })
                        .then(function (data) {
                          //const items = data;
                          //console.log(items)
                        })
                        .catch((e) => {
                          swal.fire({
                            icon: "error",
                            title: "Failed",
                            text:
                              language === "Thai"
                                ? "บัตรประชาชนไม่ตรงกับบัญชีใด ๆ  หรือ รหัสผ่านไม่ถูกต้อง"
                                : "ID card incorrect or Password incorrect",
                            //footer: '<a href="">Why do I have this issue?</a>'
                          });
                        });
                    }
                  }}
                >
                  <input
                    type="text"
                    id="IDCard"
                    className="hasicon login-idcard"
                    name="IDCard"
                    placeholder={
                      language === "Thai" ? "เลขที่บัตรประชาชน" : "ID Card"
                    }
                    autoComplete="off"
                    maxLength="13"
                    onChange={(e) => {
                      setThaiID(e.currentTarget.value);
                      handleChange(e);
                    }}
                  />
                  <span className="error d-block">{errors["IDCard"]}</span>
                  <div id="show_hide_password" style={{ position: "relative" }}>
                    <input
                      className="hasicon login-password"
                      id="IDCardPassword"
                      name="IDCardPassword"
                      type="password"
                      placeholder={
                        language === "Thai" ? "รหัสผ่าน" : "Password"
                      }
                      autoComplete="off"
                      onChange={(e) => {
                        setThaiIDPassword(e.currentTarget.value);
                        handleChange(e);
                      }}
                    />
                    <div className="input-addon">
                      <a href="/">
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>{" "}
                      </a>
                    </div>
                    <span className="error d-block">
                      {errors["IDCardPassword"]}
                    </span>
                  </div>
                  <div className="btnForgotPassword">
                    <a
                      href="/"
                      className="card-link text-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        forgotPassword();
                      }}
                    >
                      {language === "Thai" ? "ลืมรหัสผ่าน" : "Forgot Password"}
                    </a>{" "}
                  </div>
                  <div className="controlBtn">
                    <input
                      type="button"
                      className="fadeIn fourth btnSecondary"
                      value={language === "Thai" ? "ลงทะเบียน" : "Register"}
                      onClick={(e) => {
                        e.preventDefault();
                        register();
                      }}
                      style={{ margin: 0 }}
                    />
                    <input
                      type="submit"
                      className="fadeIn fourth btnPimary"
                      value={language === "Thai" ? "เข้าสู่ระบบ" : "Sign In"}
                      ref={idCardLoginButton}
                      style={{ margin: 0 }}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row">
                <div className="col-12">
                  <div className="formContent box_login">
                    <h1 className="title-header">
                      <span className="orang underline">
                        {language === "Thai" ? "เข้าสู่ระบบ" : "Login"}
                      </span>
                    </h1>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (handleValidation("PASSPORT")) {
                          userlogin({
                            variables: {
                              data: {
                                loginType: "PASSPORT",
                                username: passportID,
                                password: passportPassword,
                              },
                            },
                          })
                            .then(function (data) {
                              //const items = data;
                              //console.log(items)
                            })
                            .catch((e) => {
                              swal.fire({
                                icon: "error",
                                title: "Failed",
                                text:
                                  language === "Thai"
                                    ? "เลขพาสปอร์ตที่คุณป้อนไม่ตรงกับบัญชีใด ๆ  หรือ รหัสผ่านไม่ถูกต้อง"
                                    : "Passport incorrect or Password incorrect",
                                //footer: '<a href="">Why do I have this issue?</a>'
                              });
                            });
                        }
                      }}
                    >
                      <input
                        type="text"
                        id="PassportID"
                        className="hasicon login-idcard"
                        name="PassportID"
                        placeholder={
                          language === "Thai"
                            ? "เลขที่หนังสือเดินทาง"
                            : "Passport ID"
                        }
                        autoComplete="off"
                        maxLength="13"
                        onChange={(e) => {
                          setPassportID(e.currentTarget.value);
                          handleChange(e);
                        }}
                      />
                      <span className="error d-block">
                        {errors["PassportID"]}
                      </span>
                      <div
                        id="show_hide_password"
                        style={{ position: "relative" }}
                      >
                        <input
                          className="hasicon login-password"
                          id="PassportPassword"
                          name="PassportPassword"
                          type="password"
                          placeholder={
                            language === "Thai" ? "รหัสผ่าน" : "Password"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            setPassportPassword(e.currentTarget.value);
                            handleChange(e);
                          }}
                        />
                        <div className="input-addon">
                          <a href="/">
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                            ></i>{" "}
                          </a>
                        </div>
                        <span className="error d-block">
                          {errors["PassportPassword"]}
                        </span>
                      </div>

                      <div className="btnForgotPassword">
                        <a
                          href="/"
                          className="card-link text-dark"
                          onClick={(e) => {
                            e.preventDefault();
                            forgotPassword();
                          }}
                        >
                          {language === "Thai"
                            ? "ลืมรหัสผ่าน"
                            : "Forgot Password"}
                        </a>{" "}
                      </div>
                      <div className="controlBtn">
                        <input
                          type="button"
                          className="fadeIn fourth btnSecondary"
                          value={language === "Thai" ? "ลงทะเบียน" : "Register"}
                          onClick={(e) => {
                            e.preventDefault();
                            register();
                          }}
                          style={{ margin: 0 }}
                        />
                        <input
                          type="submit"
                          className="fadeIn fourth btnPimary"
                          value={
                            language === "Thai" ? "เข้าสู่ระบบ" : "Sign In"
                          }
                          ref={passportLoginButton}
                          style={{ margin: 0 }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="row">
                <div className="col-12">
                  <div className="formContent box_login">
                    <h1 className="title-header">
                      <span className="orang underline">
                        {language === "Thai" ? "เข้าสู่ระบบ" : "Login"}
                      </span>
                    </h1>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (handleValidation("EMAIL")) {
                          userlogin({
                            variables: {
                              data: {
                                loginType: "EMAIL",
                                username: email,
                                password: password,
                              },
                            },
                          })
                            .then(function (data) {
                              //const items = data;
                              //console.log(items)
                            })
                            .catch((e) => {
                              swal.fire({
                                icon: "error",
                                title: "Failed",
                                text:
                                  language === "Thai"
                                    ? "อีเมลที่คุณป้อนไม่ตรงกับบัญชีใด ๆ  หรือ รหัสผ่านไม่ถูกต้อง"
                                    : "Email incorrect or Password incorrect",
                                //footer: '<a href="">Why do I have this issue?</a>'
                              });
                            });
                        }
                      }}
                    >
                      <input
                        type="text"
                        id="UserEmail"
                        className="hasicon login-idcard"
                        name="UserEmail"
                        placeholder={language === "Thai" ? "อีเมล" : "Email"}
                        autoComplete="off"
                        onChange={(e) => {
                          setEmail(e.currentTarget.value);
                          handleChange(e);
                        }}
                      />
                      <span className="error d-block">
                        {errors["UserEmail"]}
                      </span>
                      <div
                        id="show_hide_password"
                        style={{ position: "relative" }}
                      >
                        <input
                          className="hasicon login-password"
                          id="EmailPassword"
                          name="EmailPassword"
                          type="password"
                          placeholder={
                            language === "Thai" ? "รหัสผ่าน" : "Password"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            setPassword(e.currentTarget.value);
                            handleChange(e);
                          }}
                        />
                        <div className="input-addon">
                          <a href="/">
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                            ></i>{" "}
                          </a>
                        </div>
                        <span className="error d-block">
                          {errors["EmailPassword"]}
                        </span>
                      </div>
                      <div className="btnForgotPassword">
                        <a
                          href="/"
                          className="card-link text-dark"
                          onClick={(e) => {
                            e.preventDefault();
                            forgotPassword();
                          }}
                        >
                          {language === "Thai"
                            ? "ลืมรหัสผ่าน"
                            : "Forgot Password"}
                        </a>{" "}
                      </div>
                      <div className="controlBtn">
                        <input
                          type="button"
                          className="fadeIn fourth btnSecondary"
                          value={language === "Thai" ? "ลงทะเบียน" : "Register"}
                          onClick={(e) => {
                            e.preventDefault();
                            register();
                          }}
                          style={{ margin: 0 }}
                        />
                        <input
                          type="submit"
                          className="fadeIn fourth btnPimary"
                          value={
                            language === "Thai" ? "เข้าสู่ระบบ" : "Sign In"
                          }
                          ref={emailLoginButton}
                          style={{ margin: 0 }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <>
        <div className="modal fade" id="ModalCarousel" tabIndex="-1" role="dialog" aria-labelledby="ModalCarouselLabel" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title col-12 text-center">{language === "Thai" ?thname:enname}</h1>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: '-2rem' }} onClick={(e)=>{e.preventDefault();closePopup();}}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ">
                {WebCoverPreview}
                <div className="col-12 ">

                  <div className="customUIPro"
                    dangerouslySetInnerHTML={{
                      __html: language === "Thai" ?thcontent:encontent,
                    }}
                  />
                  <Link
                    id="profile-btn"
                    className="active"
                    to={{
                      pathname: urlLink,
                    }}
                  >
                    {urlLink}
                  </Link>
                </div>
              </div>
              <div className="modal-footer" style={{ minHeight: '40px' }}>
                <ol className="popup-indicators">
                  {popupContent}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
