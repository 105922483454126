import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
//import 'react-summernote/lang/summernote-th-TH'; // you can import any other locale
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
import noimage from 'assets/images/no_image.svg'
import moment from "moment";
import SingleFileTable from 'components/SingleFileTable';
const swal = withReactContent(Swal)

export default function EditNews(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [fields, setFields] = useState({});
    const [errors, setError] = useState({});
    const [filespreview, setFilesPreview] = useState([]);
    const [myFiles, setMyFiles] = useState([]);
    const [galleryFiles, setGalleryFiles] = useState([]);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [imgWebCover, setIMGWebCover] = useState([]);
    const [imgMobileCover, setIMGMobileCover] = useState([]);
    const [selectActivityCat, SetSelectActivityCat] = useState([]);

    const [nameth, setNameTH] = useState('');
    const [nameen, setNameEN] = useState('');
    const [shortDetailTH, setShortDetailTH] = useState('');
    const [shortDetailEN, setShortDetailEN] = useState('');

    const [thcontent, setTHContent] = useState("");
    const [encontent, setENContent] = useState("");
    const [newsCat, setNewsCat] = useState(0);
    //const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    //const rx_int = /^\d+$/;
    //const rx_latln = /^[0-9.,]+$/;

    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(new Date());
    //const [seo, setSEO] = useState('');
    const [published, setPublished] = useState(false);
    const [pin, setPin] = useState(false);
    const [comment, SetComment] = useState(1);
    const [previewID, SetPreviewID] = useState('');
    useEffect(() => {
        //console.log(props.location.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/newsbyID', { params: { id: props.location.state.id, userID: currentUser.UserID } }).then((response) => {
            //console.log(data)
            let data = response.data[0];
            setNameTH(data.TitleTH);
            setNameEN(data.TitleEN);
            setShortDetailTH(data.ShortDetailTH);
            setShortDetailEN(data.ShortDetailEN);
            setTHContent(data.DescriptionTH);
            setENContent(data.DescriptionEN);
            setPublished(data.Published);
            setNewsCat(data.CategoriesID);
            SetPublishStartDate(new Date(data.PublishedStartDate))
            SetPublishEndDate(new Date(data.PublishedEndDate))
            SetComment(data.Comment);
            //setSEO(data.SEO)
            if (data.Pin)
                setPin(data.Pin)
            if (data.ThumbnailWeb !== null) {
                const webimageFile = {
                    file: data.ThumbnailWeb,
                    name: data.ThumbnailWeb.substring(data.ThumbnailWeb.lastIndexOf('/') + 1),
                    preview: data.ThumbnailWeb,
                    size: utils.getFileSize(data.ThumbnailWeb)
                };

                setIMGWebCover(webimageFile);
            }
            if (data.ThumbnailMobile !== null) {
                const mobileimageFile = {
                    file: data.ThumbnailMobile,
                    name: data.ThumbnailMobile.substring(data.ThumbnailMobile.lastIndexOf('/') + 1),
                    preview: data.ThumbnailMobile,
                    size: utils.getFileSize(data.ThumbnailMobile)
                };
                setIMGMobileCover(mobileimageFile);
            }
            //setMyFiles(data.filelist);
            const newFiles = [...myFiles];
            let loop = 0;
            for (let i = 0; i < data.filelist.length; i += 1) {
                let item = data.filelist[i];
                if (item.AttachmentType !== 'IMG' && item.AttachmentType !== 'VDO') {
                    console.log(item.AttachmentType);
                    const filedata = {
                        //file: newImageFile[0],
                        id: item.AttachmentID,
                        path: item.FilenameNew,
                        name: item.FilenameOld,
                        preview: item.AttachmentPath,
                        size: item.Filesize
                    };

                    newFiles[loop] = filedata;

                    setMyFiles(newFiles);
                    loop++;

                }
                //console.log((item.AttachmentType !== 'IMAGE' && item.AttachmentType !== 'VDO'));
            }
            const newgalleryFiles = [...galleryFiles];
            loop = 0;
            for (let i = 0; i < data.filelist.length; i += 1) {
                let item = data.filelist[i];
                if (item.AttachmentType === 'IMG') {
                    const filedata = {
                        //file: newImageFile[0],
                        id: data.filelist[i].AttachmentID,
                        path: data.filelist[i].FilenameNew,
                        name: data.filelist[i].FilenameOld,
                        preview: data.filelist[i].AttachmentPath,
                        size: data.filelist[i].Filesize
                    };

                    newgalleryFiles[loop] = filedata;
                    setGalleryFiles(newgalleryFiles);
                    loop++;
                }
            }
            const newmediaFiles = [...mediaFiles];
            loop = 0;
            for (let i = 0; i < data.filelist.length; i += 1) {
                let item = data.filelist[i];
                if (item.AttachmentType === 'VDO') {
                    const filedata = {
                        //file: newImageFile[0],
                        id: data.filelist[i].AttachmentID,
                        path: data.filelist[i].FilenameNew,
                        name: data.filelist[i].FilenameOld,
                        preview: data.filelist[i].AttachmentPath,
                        size: data.filelist[i].Filesize
                    };

                    newmediaFiles[loop] = filedata;
                    setMediaFiles(newmediaFiles);
                    loop++;
                }
            }

        })
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchNewsCat', { params: { status: 1 } })
            .then((response) => {
                SetSelectActivityCat(response.data);
            });
    }, []); /* <-- add this for run once*/
    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);
    const ActivityCatSelect = selectActivityCat.map((newsCat, index) => (
        <option
            key={index}
            value={newsCat.CategoriesID}
        >
            {newsCat.TitleTH}
        </option>
    ));
    const files = myFiles.map((file, i) => (

        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const gallery = galleryFiles.map((file, i) => (

        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveGalleryFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const media = mediaFiles.map((file, i) => (

        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveMediaFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const HandlePin = (pinStatus) => {
        if (pinStatus === true) {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getNewsPinCount')
                .then((response) => {
                    //console.log(response.data[0].PinCount)
                    if (Number(response.data[0].PinCount) >= 3) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ผิดพลาด',
                            text: 'มีข้อมูลในการปักหมุดครบจำนวนสามชุดแล้ว',
                        })
                    }
                    else {
                        setPin(pinStatus)
                    }
                });
        }
        else {
            setPin(pinStatus)
        }
    }
    const handleRemoveFile = filename => {
        for (let file of myFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setMyFiles(myFiles.filter(item => item['name'] !== filename));
    };

    const handleRemoveGalleryFile = filename => {
        for (let file of galleryFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setGalleryFiles(galleryFiles.filter(item => item['name'] !== filename));
    };

    const handleRemoveMediaFile = filename => {
        for (let file of mediaFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setMediaFiles(mediaFiles.filter(item => item['name'] !== filename));
    };

    const WebCoverPreview = (
        <img className="preview-upload" src={typeof imgWebCover.preview === 'undefined' ? noimage : imgWebCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgWebCover); }} alt="Web Cover Preview" />
    );

    const MobileCoverPreview = (
        <img className="preview-upload" src={typeof imgMobileCover.preview === 'undefined' ? noimage : imgMobileCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgMobileCover); }} alt="Mobile Cover Preview" />
    );

    const handleImgWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGWebCover(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGMobileCover(imageFile);
    };

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (nameth.trim().length === 0) {
            formIsValid = false;
            serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (thcontent.trim().length === 0) {
            formIsValid = false;
            serrors["thcontent"] = "กรุณาระบุรายละเอียดภาษาไทย";
        }
        /*if (nameen.trim().length===0) {
            formIsValid = false;
            serrors["nameen"] = "กรุณาระบุชื่อภาษาอังกฤษ";
        }
        if(shortDetailEN.trim().length===0){
            formIsValid = false;
            serrors["shortDetailEN"] = "กรุณาระบุรายละเอียดแบบย่อภาษาอังกฤษ";
        }
        if(encontent.trim().length===0){
            formIsValid = false;
            serrors["encontent"] = "กรุณาระบุรายละเอียดภาษาอังกฤษ";
        }*/
        if (newsCat === 0) {
            formIsValid = false;
            serrors["newsCat"] = "กรุณาเลือกหมวดหมู่ข่าวประชาสัมพันธ์";
        }

        if (imgWebCover.length === 0) {
            formIsValid = false;
            serrors["webcover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับเว็บ";
        }
        if (imgMobileCover.length === 0) {
            formIsValid = false;
            serrors["mobilecover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับมือถือ";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", props.location.state.id);
            formData.append("thname", nameth);
            formData.append("thcontent", thcontent);
            formData.append("enname", nameen);
            formData.append("encontent", encontent);
            formData.append("shortDetailTH", shortDetailTH);
            formData.append("shortDetailEN", shortDetailEN);
            formData.append("catid", newsCat);

            //formData.append("seo", seo);
            formData.append("pin", pin);
            formData.append("comment", comment);

            formData.append("published", published);
            formData.append("publishstartDate", publishstartDate);
            /*if (publishendDate !== null) {
                formData.append("publishendDate", publishendDate);
            }
            else {
                const date = new Date();
                let tmppublishendDate = new Date(date.setTime(date.getTime() + 50 * 365 * 86400000));
                formData.append("publishendDate", tmppublishendDate);
            }*/
            formData.append("publishendDate", publishendDate);
            formData.append("webcoverfile", imgWebCover.file);
            formData.append("mobilecoverfile", imgMobileCover.file);
            formData.append("updateby", currentUser.UserID);
            for (let i = 0; i < myFiles.length; i += 1) {
                formData.append("files[]", myFiles[i]);
            }
            for (let i = 0; i < galleryFiles.length; i += 1) {
                formData.append("files[]", galleryFiles[i]);
            }
            for (let i = 0; i < mediaFiles.length; i += 1) {
                formData.append("files[]", mediaFiles[i]);
            }

            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateNews', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/news/News" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const PreviewData = () => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", previewID);
            formData.append("editnewsid", props.location.state.id);
            formData.append("thname", nameth);
            formData.append("thcontent", thcontent);
            formData.append("enname", nameen);
            formData.append("encontent", encontent);
            formData.append("shortDetailTH", shortDetailTH);
            formData.append("shortDetailEN", shortDetailEN);
            formData.append("catid", newsCat);
            formData.append("pin", pin);
            formData.append("comment", comment);

            formData.append("published", 0);
            formData.append("publishstartDate", publishstartDate);

            formData.append("publishendDate", publishendDate);

            formData.append("webcoverfile", imgWebCover.file);
            formData.append("mobilecoverfile", imgMobileCover.file);
            formData.append("userID", currentUser.UserID);
            for (let i = 0; i < myFiles.length; i += 1) {
                formData.append("files[]", myFiles[i]);
            }
            for (let i = 0; i < galleryFiles.length; i += 1) {
                formData.append("files[]", galleryFiles[i]);
            }
            for (let i = 0; i < mediaFiles.length; i += 1) {
                formData.append("files[]", mediaFiles[i]);
            }
            if (previewID.trim().length === 0) {
                axios.post(process.env.REACT_APP_APIURL + '/api-web/saveNewsPreview', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`
                    }
                })
                    .then((response) => {
                        console.log(response.data)
                        SetPreviewID(response.data.id);
                        window.open("/news/Previews/" + response.data.id, "_blank");
                    });
            }
            else {
                axios.post(process.env.REACT_APP_APIURL + '/api-web/updateNewsPreview', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`
                    }
                })
                    .then((response) => {
                        window.open("/news/Previews/" + previewID, "_blank");
                    });
            }

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    }
    const cancel = () => {
        props.history.push({ pathname: "/admin/news/News" });
    };
    const handleRemoveWebCover = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", imgWebCover.file);
        formData.append("fieldName", 'ThumbnailWeb');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteNewsCover', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setIMGWebCover('undefined');
    };
    const handleRemoveMobileCover = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", imgMobileCover.file);
        formData.append("fieldName", 'ThumbnailMobile');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteNewsCover', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setIMGMobileCover('undefined');
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการการประชาสัมพันธ์"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการการประชาสัมพันธ์ </a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/news/News" }}>{"จัดการประชาสัมพันธ์"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/news/EditNews" }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="nameth" className="required">ชื่อเรื่อง (ภาษาไทย)</label>
                                            <input type="text" className="form-control" id="nameth" name="nameth" placeholder="กรุณากรอก" value={nameth} onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                            <SError error={errors["nameth"]} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="shortDetailTH">รายละเอียดแบบย่อ (ภาษาไทย)</label>
                                            <div>
                                                <textarea className="form-control" name="shortDetailTH" id="shortDetailTH" rows="3" value={shortDetailTH} onChange={e => { setShortDetailTH(e.target.value) }} placeholder="กรุณากรอก"></textarea>
                                                <SError error={errors["shortDetailTH"]} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="required">รายละเอียด (ภาษาไทย)</label>
                                            <ReactSummernote
                                                value={thcontent}
                                                options={{
                                                    //lang: 'th-TH',
                                                    placeholder: "กรุณากรอก",
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setTHContent}
                                            />
                                            <SError error={errors["thcontent"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="nameen" >ชื่อเรื่อง (ภาษาอังกฤษ)</label>
                                            <input type="text" className="form-control" id="nameen" name="nameen" placeholder="กรุณากรอก" value={nameen} onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                            <SError error={errors["nameen"]} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="shortDetailEN">รายละเอียดแบบย่อ (ภาษาอังกฤษ)</label>
                                            <div>
                                                <textarea className="form-control" name="shortDetailEN" id="shortDetailEN" rows="3" value={shortDetailEN} onChange={e => { setShortDetailEN(e.target.value) }} placeholder="กรุณากรอก"></textarea>
                                                <SError error={errors["shortDetailEN"]} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label >รายละเอียด (ภาษาอังกฤษ)</label>
                                            <ReactSummernote
                                                value={encontent}
                                                options={{
                                                    //lang: 'th-TH',
                                                    placeholder: "กรุณากรอก",
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setENContent}
                                            />
                                            <SError error={errors["encontent"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-5">
                                    <label htmlFor="newsCat" className="required">หมวดหมู่</label>
                                    <select className="form-control mb-2 mr-2" id="newsCat" name="newsCat" value={newsCat} onChange={(e) => { setNewsCat(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ActivityCatSelect}
                                    </select>
                                    <SError error={errors["newsCat"]} />
                                </div>


                                <div className="col-12">
                                    <label className="required mr-2">ปักหมุด</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin1" value="1" checked={pin === true} onChange={(e) => { HandlePin(true); }} />
                                        <label className="form-check-label" htmlFor="pin1"> ปักหมุด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin2" value="0" checked={pin === false} onChange={(e) => { HandlePin(false); }} />
                                        <label className="form-check-label" htmlFor="pin2"> ไม่ปักหมุด</label>
                                    </div>
                                    <SError error={errors["pin"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published1" value="1" checked={published === true} onChange={(e) => { setPublished(true); }} />
                                        <label className="form-check-label" htmlFor="published1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published2" value="0" checked={published === false} onChange={(e) => { setPublished(false); }} />
                                        <label className="form-check-label" htmlFor="published2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["published"]} />
                                </div>
                                {published === true && <>
                                    <div className="col-5">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                /*maxDate={publishendDate}*/
                                                onChange={SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-5">
                                        <label>วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                /*minDate={publishstartDate}*/
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-2"></div>
                                </>}
                                <div className="col-12">
                                    <label className="required mr-2">แสดงความคิดเห็น</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment1" value="1" checked={comment === 1} onChange={(e) => { SetComment(1) }} />
                                        <label className="form-check-label" htmlFor="comment1"> เปิดแสดงความคิดเห็นทั้งหมด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment2" value="2" checked={comment === 2} onChange={(e) => { SetComment(2) }} />
                                        <label className="form-check-label" htmlFor="comment2"> ปิดแสดงความคิดเห็นทั้งหมด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment3" value="3" checked={comment === 3} onChange={(e) => { SetComment(3) }} />
                                        <label className="form-check-label" htmlFor="comment3"> เปิดแสดงความคิดเห็นอย่างเดียว</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment4" value="4" checked={comment === 4} onChange={(e) => { SetComment(4) }} />
                                        <label className="form-check-label" htmlFor="comment4"> ปิดกล่องแสดงความคิดเห็น</label>
                                    </div>
                                    <SError error={errors["comment"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพหน้าปก สำหรับเว็บไซต์</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgWebCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {WebCoverPreview}
                                                <SingleFileTable filedata={imgWebCover} removefn={handleRemoveWebCover} />
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["webcover"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพหน้าปก สำหรับมือถือ</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgMobileCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MobileCoverPreview}
                                                <SingleFileTable filedata={imgMobileCover} removefn={handleRemoveMobileCover} />
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["mobilecover"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" >ไฟล์แนบ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setMyFiles([...myFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {files}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" >แกลลอรี่</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setGalleryFiles([...galleryFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".jpg,.jpeg,.gif,.png"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {gallery}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" >สื่อมัลติมีเดีย</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setMediaFiles([...mediaFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".mp3,.mp4, .avi, .flv, .wma, .mpeg, .mpeg4"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {media}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-primary mr-2" onClick={e => { e.preventDefault(); PreviewData(); }}><i className="far fa-newspaper"></i> Preview</button>
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
