import {React, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MomentUtils from "@date-io/moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import "moment/locale/th";
import {scroller} from "react-scroll";
import Navbar from "../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import idcardico from "assets/images/icon/idcard.svg";
import passportico from "assets/images/icon/passport.svg";
import banner from "assets/images/banner/advertise.svg";
import SError from "components/SError";
import {auto} from "@popperjs/core";
import useWindowSize from "../../utils/UseWindowSize";

const swal = withReactContent(Swal);

export default function Register(props) {
  const language = localStorage.getItem("language");
  const [width] = useWindowSize();
  const [fields, setFields] = useState({});
  const [errors, setError] = useState({});
  const [interrestTopic, SetInterrestTopic] = useState([]);
  const [vertifyQuestion, setVertifyQuestion] = useState([]);
  const [idcardgender, setIDCardGender] = useState("");
  const [idcardhri, setIDCardHRI] = useState(0);
  const [passportgender, setPassportGender] = useState("");
  const [idPhone, setIDPhone] = useState("");
  const [passportPhone, setPassportPhone] = useState("");
  const [birthDate, SetBirthDate] = useState(new Date());
  const [passporthri, setPassportHRI] = useState(0);

  const rx_int = /^\d+$/;
  const handlePhoneChange = (e) => {
    if (rx_int.test(e.target.value)) {
      let sfields = fields;
      sfields[e.target.name] = e.target.value;
      if (e.target.name === "IDCardPhone") setIDPhone(e.target.value);
      else if (e.target.name === "PassportPhone")
        setPassportPhone(e.target.value);
      setFields(sfields);
    }
  };
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getMemberInterestTopic")
      .then((response) => {
        SetInterrestTopic(response.data);
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchVertifyQuestion", {
        params: {status: 1},
      })
      .then((response) => {
        setVertifyQuestion(response.data);
      });
    $(function () {
      $("#show_hide_password a").on("click", function (event) {
        event.preventDefault();
        if ($("#show_hide_password input").attr("type") === "text") {
          $("#show_hide_password input").attr("type", "password");
          $("#show_hide_password i").addClass("fa-eye-slash");
          $("#show_hide_password i").removeClass("fa-eye");
        } else if ($("#show_hide_password input").attr("type") === "password") {
          $("#show_hide_password input").attr("type", "text");
          $("#show_hide_password i").removeClass("fa-eye-slash");
          $("#show_hide_password i").addClass("fa-eye");
        }
      });
    });
    return () => {
      //cleanup
    };
  }, []);
  const TopicSelect = interrestTopic.map((item) => (
    <option key={item.InterestTopicID} value={item.InterestTopicID}>
      {language === "Thai"
        ? item.TextTH
        : item.TextEN.trim().length === 0
          ? item.TextTH
          : item.TextEN}
    </option>
  ));
  const QuestionSelect = vertifyQuestion.map((item) => (
    <option key={item.VerifyQuestionID} value={item.VerifyQuestionID}>
      {language === "Thai"
        ? item.TextTH
        : item.TextEN.trim().length === 0
          ? item.TextTH
          : item.TextEN}
    </option>
  ));
  const handleChange = (e) => {
    let sfields = fields;
    sfields[e.target.name] = e.target.value;
    setFields(sfields);
  };

  const handlePasswordChange = (e) => {
    let sfields = fields;
    sfields[e.target.name] = e.target.value;
    setFields(sfields);
  };

  function chkDigitPid(p_iPID) {
    var total = 0;
    var iPID;
    var chk;
    var Validchk;
    iPID = p_iPID.replace(/-/g, "");
    Validchk = iPID.substr(12, 1);
    var j = 0;
    var pidcut;
    for (var n = 0; n < 12; n++) {
      pidcut = parseInt(iPID.substr(j, 1));
      total = total + pidcut * (13 - n);
      j++;
    }

    chk = 11 - (total % 11);

    if (chk == 10) {
      chk = 0;
    } else if (chk == 11) {
      chk = 1;
    }
    if (chk == Validchk) {
      //alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
      return true;
    } else {
      //alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      return false;
    }
  }

  const handleValidation = (loginType) => {
    let serrors = {};
    let formIsValid = true;
    if (loginType === "THAIID") {
      if (!fields["IDCard"]) {
        formIsValid = false;
        serrors["IDCard"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["IDCard"] !== "undefined") {
        if (!isFinite(String(fields["IDCard"]))) {
          formIsValid = false;
          serrors["IDCard"] =
            "หมายเลขบัตรประชาชนต้องประกอบไปด้วยตัวเลขเท่านั้น";
        }
        if (fields["IDCard"].length !== 13) {
          formIsValid = false;
          serrors["IDCard"] = "กรุณากรอกหมายเลข 13 หลัก";
        }
        /*if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["IDCard"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["IDCard"].length - lastDotPos) > 2)) {
                  formIsValid = false;
                  serrors["IDCard"] = "รูปแบบของหมายเลขบัตรประชาชนไม่ถูกต้อง";
                }*/
        if (chkDigitPid(fields["IDCard"]) === false) {
          formIsValid = false;
          serrors["IDCard"] = "หมายเลขบัตรประชาชนไม่ถูกต้อง";
        }
      }
      if (!fields["IDCardFirstName"]) {
        formIsValid = false;
        serrors["IDCardFirstName"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["IDCardLastName"]) {
        formIsValid = false;
        serrors["IDCardLastName"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["IDCardGender"]) {
        formIsValid = false;
        serrors["IDCardGender"] = "กรุณาระบุเพศ";
      }
      if (typeof fields["IDCardGender"] !== "undefined") {
        if (fields["IDCardGender"] === "")
          serrors["IDCardGender"] = "กรุณาระบุเพศ";
      }
      if (!fields["IDCardPhone"]) {
        formIsValid = false;
        serrors["IDCardPhone"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["IDCardHRI"]) {
        formIsValid = false;
        serrors["IDCardHRI"] = "กรุณาเลือกประเด็นสิทธิมนุษยชนที่สนใจ";
      }
      if (typeof fields["IDCardHRI"] !== "undefined") {
        if (fields["IDCardHRI"] === "0")
          serrors["IDCardHRI"] = "กรุณาเลือกประเด็นสิทธิมนุษยชนที่สนใจ";
      }

      if (!fields["IDCardEmail"]) {
        formIsValid = false;
        serrors["IDCardEmail"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["IDCardEmail"] !== "undefined") {
        let lastAtPos = fields["IDCardEmail"].lastIndexOf("@");
        let lastDotPos = fields["IDCardEmail"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["IDCardEmail"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["IDCardEmail"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          serrors["IDCardEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
        }
      }
      if (!fields["IDCardPassword"]) {
        formIsValid = false;
        serrors["IDCardPassword"] = "กรุณากรอกข้อมูล";
      }
      if (typeof fields["IDCardPassword"] !== "undefined") {
        var format = /[!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?]+/;
        var dformat = /["']/g;
        if (dformat.test(fields["IDCardPassword"]) === true) {
          formIsValid = false;
          serrors["IDCardPassword"] =
            "รหัสผ่านต้องไม่มีสัญลักษณ์ ' (single quote) และ \" (double quote)";
        }
        if (format.test(fields["IDCardPassword"]) === false) {
          formIsValid = false;
          serrors["IDCardPassword"] =
            "รหัสผ่านต้องมีเครื่องหมายหรืออักขระพิเศษ (!@#$%^&*()_+|~-={}[]:;<>?,./) อย่างน้อย 1 ตัว";
        }
        var number = /[0-9]+/g;
        if (number.test(fields["IDCardPassword"]) === false) {
          formIsValid = false;
          serrors["IDCardPassword"] =
            "รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว";
        }
        var upper = /[A-Z]+/g;
        if (upper.test(fields["IDCardPassword"]) === false) {
          formIsValid = false;
          serrors["IDCardPassword"] =
            "รหัสผ่านต้องมีตัวอักษรตัวใหญ่ (A-Z) อย่างน้อย 1 ตัว";
        }
        var lower = /[a-z]+/g;
        if (lower.test(fields["IDCardPassword"]) === false) {
          formIsValid = false;
          serrors["IDCardPassword"] =
            "รหัสผ่านต้องมีตัวอักษรตัวเล็ก (a-z) อย่างน้อย 1 ตัว";
        }
        if (fields["IDCardPassword"].length < 8) {
          formIsValid = false;
          serrors["IDCardPassword"] = "รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร";
        }
      }

      if (!fields["IDCardConfirmPassword"]) {
        formIsValid = false;
        serrors["IDCardConfirmPassword"] = "กรุณากรอกข้อมูล";
      }
      if (typeof fields["IDCardConfirmPassword"] !== "undefined") {
        if (typeof fields["IDCardPassword"] !== "undefined") {
          if (fields["IDCardPassword"] !== fields["IDCardConfirmPassword"]) {
            formIsValid = false;
            serrors["IDCardConfirmPassword"] =
              "รหัสผ่านยืนยันต้องตรงกับรหัสผ่านที่ตั้งไว้";
          }
        }
      }

      if (!fields["IDCardQuestion"]) {
        formIsValid = false;
        serrors["IDCardQuestion"] = "กรุณาเลือกคำถามยืนยันตน";
      }

      if (!fields["IDCardAnswer"]) {
        formIsValid = false;
        serrors["IDCardAnswer"] = "กรุณากรอกข้อมูล";
      }
    } else if (loginType === "PASSPORT") {
      if (!fields["PassportID"]) {
        formIsValid = false;
        serrors["PassportID"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["PassportFirstName"]) {
        formIsValid = false;
        serrors["PassportFirstName"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["PassportLastName"]) {
        formIsValid = false;
        serrors["PassportLastName"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["PassportGender"]) {
        formIsValid = false;
        serrors["PassportGender"] = "กรุณาระบุเพศ";
      }
      if (typeof fields["PassportGender"] !== "undefined") {
        if (fields["PassportGender"] === "")
          serrors["PassportGender"] = "กรุณาระบุเพศ";
      }
      if (!fields["PassportPhone"]) {
        formIsValid = false;
        serrors["PassportPhone"] = "กรุณากรอกข้อมูล";
      }
      if (!fields["PassportHRI"]) {
        formIsValid = false;
        serrors["PassportHRI"] = "กรุณาเลือกประเด็นสิทธิมนุษยชนที่สนใจ";
      }
      if (typeof fields["PassportHRI"] !== "undefined") {
        if (fields["PassportHRI"] === "0")
          serrors["PassportHRI"] = "กรุณาเลือกประเด็นสิทธิมนุษยชนที่สนใจ";
      }
      if (!fields["PassportEmail"]) {
        formIsValid = false;
        serrors["PassportEmail"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["PassportEmail"] !== "undefined") {
        let lastAtPos = fields["PassportEmail"].lastIndexOf("@");
        let lastDotPos = fields["PassportEmail"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["PassportEmail"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["PassportEmail"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          serrors["PassportEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
        }
      }
      if (!fields["PassportPassword"]) {
        formIsValid = false;
        serrors["PassportPassword"] = "กรุณากรอกข้อมูล";
      }
      if (typeof fields["PassportPassword"] !== "undefined") {
        var format = /[!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?]+/;
        var dformat = /["']/g;
        if (dformat.test(fields["PassportPassword"]) === true) {
          formIsValid = false;
          serrors["PassportPassword"] =
            "รหัสผ่านต้องไม่มีสัญลักษณ์ ' (single quote) และ \" (double quote)";
        }
        if (format.test(fields["PassportPassword"]) === false) {
          formIsValid = false;
          serrors["PassportPassword"] =
            "รหัสผ่านต้องมีเครื่องหมายหรืออักขระพิเศษ (!@#$%^&*()_+|~-={}[]:;<>?,./) อย่างน้อย 1 ตัว";
        }
        var number = /[0-9]+/g;
        if (number.test(fields["PassportPassword"]) === false) {
          formIsValid = false;
          serrors["PassportPassword"] =
            "รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว";
        }
        var upper = /[A-Z]+/g;
        if (upper.test(fields["PassportPassword"]) === false) {
          formIsValid = false;
          serrors["PassportPassword"] =
            "รหัสผ่านต้องมีตัวอักษรตัวใหญ่ (A-Z) อย่างน้อย 1 ตัว";
        }
        var lower = /[a-z]+/g;
        if (lower.test(fields["PassportPassword"]) === false) {
          formIsValid = false;
          serrors["PassportPassword"] =
            "รหัสผ่านต้องมีตัวอักษรตัวเล็ก (a-z) อย่างน้อย 1 ตัว";
        }
        if (fields["PassportPassword"].length < 8) {
          formIsValid = false;
          serrors["PassportPassword"] = "รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร";
        }
      }

      if (!fields["PassportConfirmPassword"]) {
        formIsValid = false;
        serrors["PassportConfirmPassword"] = "กรุณากรอกข้อมูล";
      }
      if (typeof fields["PassportConfirmPassword"] !== "undefined") {
        if (fields["PassportConfirmPassword"].length < 4) {
          formIsValid = false;
          serrors["PassportConfirmPassword"] =
            "รหัสผ่านต้องไม่น้อยกว่า 4 ตัวอักษร";
        }
        if (typeof fields["PassportPassword"] !== "undefined") {
          if (
            fields["PassportPassword"] !== fields["PassportConfirmPassword"]
          ) {
            formIsValid = false;
            serrors["PassportConfirmPassword"] =
              "รหัสผ่านยืนยันต้องตรงกับรหัสผ่านที่ตั้งไว้";
          }
        }
      }
      if (!fields["PassportQuestion"]) {
        formIsValid = false;
        serrors["PassportQuestion"] = "กรุณาเลือกคำถามยืนยันตน";
      }

      if (!fields["PassportAnswer"]) {
        formIsValid = false;
        serrors["PassportAnswer"] = "กรุณากรอกข้อมูล";
      }
    }
    setError(serrors);
    return formIsValid;
  };
  const Register = (loginType) => {
    if (loginType === "THAIID") {

      axios({
        method: 'post',
        url: process.env.REACT_APP_APIURL + "/api-web/registerbyIDCard",
        headers: {},
        data: {
          idcard   : fields["IDCard"],
          email    : fields["IDCardEmail"],
          firstname: fields["IDCardFirstName"],
          lastname : fields["IDCardLastName"],
          gender   : fields["IDCardGender"],
          birthDate: birthDate,
          phone    : fields["IDCardPhone"],
          hri      : fields["IDCardHRI"],
          password : fields["IDCardPassword"],
          question : fields["IDCardQuestion"],
          answer   : fields["IDCardAnswer"],
        }
      }).then((response) => {
        if (response.data.status === false) {
          swal.fire({
            icon: "error",
            title: "Failed",
            text: response.data.text,
          });
        } else {
          Swal.fire({
            icon: "success",
            title:
              language === "Thai"
                ? "บันทึกข้อมูลการสมัครเรียบร้อย"
                : "Registration information has been saved.",
            showDenyButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: language === "Thai" ? "ตกลง" : "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          });
        }
      })
      .catch((error) => {
        swal.fire({
          icon: "error",
          title: "Failed",
          text: error.response.data.text || error.message,
        });
      });

    } else if (loginType === "PASSPORT") {
        axios({
          method: 'post',
          url: process.env.REACT_APP_APIURL + "/api-web/registerbyPassport",
          headers: {},
          data: {
            passportid: fields["PassportID"],
            email     : fields["PassportEmail"],
            firstname : fields["PassportFirstName"],
            lastname  : fields["PassportLastName"],
            gender    : fields["PassportGender"],
            birthDate : birthDate,
            phone     : fields["PassportPhone"],
            hri       : fields["PassportHRI"],
            password  : fields["PassportPassword"],
            question  : fields["PassportQuestion"],
            answer    : fields["PassportAnswer"],
          }
        })
        .then((response) => {
          if (response.data.status === false) {
            swal.fire({
              icon: "error",
              title: "Failed",
              text: response.data.text,
            });
          } else {
            Swal.fire({
              icon: "success",
              title:
                language === "Thai"
                  ? "บันทึกข้อมูลการสมัครเรียบร้อย"
                  : "Registration information has been saved.",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: language === "Thai" ? "ตกลง" : "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/";
              }
            });
          }
        }) 
        .catch((error) => {
          swal.fire({
            icon: "error",
            title: "Failed",
            text: error.response.data.text || error.message,
          });
        });
    }
  };
  return (
    <>
      {width > 991 && (
        <div id="header-wrapper">
          {/*{<Navbar />}*/}
          <ContentBanner
            language={language}
            theader={"ลงทะเบียน"}
            eheader={"Login / Register"}
            banner={banner}
            path={
              <>
                <span>/</span>
                <Link
                  id="profile-btn"
                  className="active"
                  to={{
                    pathname: "/authen/Register",
                    state: {language: language},
                  }}
                >
                  {language === "Thai" ? "ลงทะเบียน" : "Register"}
                </Link>
              </>
            }
          />
        </div>
      )}
      <main className="content-page">
        <div className="container">
          <ul
            id="icon-tab"
            className="nav nav-pills mb-3 mx-auto justify-content-center list_respon"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="bg-orang icon-circle">
                    <img src={idcardico} alt="ID Card"/>
                  </div>
                  <span className="button-tab">
                    {language === "Thai" ? "บัตรประชาชน" : "ID Card"}
                  </span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="true"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {" "}
                  <div className="bg-orang icon-circle">
                    <img src={passportico} alt="ID Card"/>
                  </div>
                  <span className="button-tab">
                    {language === "Thai" ? "หนังสือเดินทาง" : "Passport"}
                  </span>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="mhk-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="formContent box_forgotpassword">
                <h1 className="title-header">
                  <span className="orang underline">
                    {language === "Thai" ? "ลงทะเบียน" : "Register"}
                  </span>
                </h1>
                <form
                  className="row form-center"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (handleValidation("THAIID")) {
                      Register("THAIID");
                    }
                  }}
                >
                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="text"
                      id="IDCard"
                      name="IDCard"
                      placeholder={
                        language === "Thai" ? "เลขที่บัตรประชาชน" : "ID Card"
                      }
                      autoComplete="off"
                      maxLength="13"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="error d-block">{errors["IDCard"]}</span>
                  </div>
                  {/* <div className="col-md-6 col-xs-12">
                    <div className="row">
                      <div
                        className="mr-2 text-center"
                        style={{
                          marginTop: auto,
                          marginTop: -15 + "px",
                          marginLeft: 10,
                          color: "#999",
                        }}
                      >
                        {language === "Thai" ? "วันเกิด" : "Birthday"}
                      </div>
                      <div
                        className="no-underline"
                        style={{
                          width: "100%",
                          paddingLeft: 15 + "px",
                        }}
                      >
                        {language === "Thai" && (
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={"th"}
                          >
                            <DatePicker
                              openTo="year"
                              views={["year", "month", "date"]}
                              value={birthDate}
                              format="D MMMM YYYY"
                              pickerHeaderFormat="ddd D MMM"
                              yearOffset={543}
                              maxDate={new Date()}
                              onChange={SetBirthDate}
                              name="birthDate"
                              id="birthDate"
                            />
                            <div className="input-addon">
                              // <span className="require">*</span>
                            </div>
                          </MuiPickersUtilsProvider>
                        )}
                        {language !== "Thai" && (
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={"en"}
                          >
                            <DatePicker
                              openTo="year"
                              views={["year", "month", "date"]}
                              value={birthDate}
                              format="D MMMM YYYY"
                              pickerHeaderFormat="ddd D MMM"
                              maxDate={new Date()}
                              onChange={SetBirthDate}
                              name="birthDate"
                              id="birthDate"
                            />
                            <div className="input-addon">
                              // <span className="require">*</span>
                            </div>
                          </MuiPickersUtilsProvider>
                        )}
                      </div>
                    </div>
                    <SError error={errors["birthDate"]} />
                  </div> */}

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="text"
                      id="IDCardFirstName"
                      name="IDCardFirstName"
                      placeholder={language === "Thai" ? "ชื่อ" : "Name"}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="error d-block">
                      {errors["IDCardFirstName"]}
                    </span>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="text"
                      id="IDCardLastName"
                      name="IDCardLastName"
                      placeholder={language === "Thai" ? "นามสกุล" : "Surname"}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="error d-block">
                      {errors["IDCardLastName"]}
                    </span>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <select
                      id="IDCardGender"
                      name="IDCardGender"
                      className="custom-select"
                      defaultValue=""
                      onChange={(e) => {
                        setIDCardGender(e.currentTarget.value);
                        handleChange(e);
                      }}
                    >
                      <option value="">
                        {language === "Thai" ? "เลือกเพศ" : "Select Gender"}
                      </option>
                      <option value="M" checked={idcardgender === "M"}>
                        {language === "Thai" ? "ชาย" : "Male"}
                      </option>
                      <option value="F" checked={idcardgender === "F"}>
                        {language === "Thai" ? "หญิง" : "Female"}
                      </option>
                      <option value="O" checked={idcardgender === "O"}>
                        {language === "Thai" ? "อื่น ๆ" : "Other"}
                      </option>
                    </select>
                    <span className="error d-block">
                      {errors["IDCardGender"]}
                    </span>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="text"
                      id="IDCardPhone"
                      name="IDCardPhone"
                      value={idPhone}
                      placeholder={
                        language === "Thai"
                          ? "หมายเลขโทรศัพท์มือถือ"
                          : "Mobile Number"
                      }
                      maxLength={10}
                      autoComplete="off"
                      onChange={(e) => {
                        handlePhoneChange(e);
                      }}
                    />
                    <span className="error d-block">
                      {errors["IDCardPhone"]}
                    </span>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <select
                      id="IDCardHRI"
                      name="IDCardHRI"
                      className="custom-select"
                      defaultValue="0"
                      onChange={(e) => {
                        setIDCardHRI(e.currentTarget.value);
                        handleChange(e);
                      }}
                    >
                      <option value="0">
                        {language === "Thai"
                          ? "ประเด็นสิทธิมนุษยชนที่สนใจ"
                          : "Human rights issues of interest"}
                      </option>
                      {TopicSelect}
                    </select>
                    <span className="error d-block">{errors["IDCardHRI"]}</span>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="text"
                      id="IDCardEmail"
                      name="IDCardEmail"
                      placeholder={language === "Thai" ? "อีเมล" : "Email"}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="error d-block">
                      {errors["IDCardEmail"]}
                    </span>
                  </div>

                  <div id="show_hide_password" className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="password"
                      id="IDCardPassword"
                      name="IDCardPassword"
                      placeholder={
                        language === "Thai" ? "รหัสผ่าน" : "Password"
                      }
                      autoComplete="off"
                      onChange={(e) => {
                        handlePasswordChange(e);
                      }}
                    />
                    <div className="input-addon">
                      <a href="/">
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>{" "}
                      </a>
                    </div>
                    <span className="error d-block">
                      {errors["IDCardPassword"]}
                    </span>
                  </div>

                  <div id="show_hide_password" className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="password"
                      id="IDCardConfirmPassword"
                      name="IDCardConfirmPassword"
                      placeholder={
                        language === "Thai"
                          ? "ยืนยันรหัสผ่าน"
                          : "Confirm Password"
                      }
                      autoComplete="off"
                      onChange={(e) => {
                        handlePasswordChange(e);
                      }}
                    />
                    <div className="input-addon">
                      <a href="/">
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>{" "}
                      </a>
                    </div>
                    <span className="error d-block">
                      {errors["IDCardConfirmPassword"]}
                    </span>
                  </div>
                  <div className="col-md-6 col-xs-12 text-center">
                    <ul
                      className="listPassword"
                      style={{
                        listStyleType: "square",
                        textAlign: "left",
                        margin: "0 auto",
                      }}
                    >
                      การตั้งรหัสผ่าน ต้องมีความยาวอย่างน้อย 8
                      ตัวอักษรหรือมากกว่านั้น
                      <li>- ตัวอักษรตัวเล็ก (a-z) อย่างน้อย 1 ตัว</li>
                      <li>- ตัวอักษรตัวใหญ่ (A-Z) อย่างน้อย 1 ตัว</li>
                      <li>- ตัวเลข (0-9) อย่างน้อย 1 ตัว</li>
                      <li>
                        {
                          "- เครื่องหมายหรืออักขระพิเศษ  (!@#$%^&*()_+|~-={}[]:;<>?,./) อย่างน้อย 1 ตัว"
                        }
                      </li>
                      <li>
                        {
                          "- ห้ามมีอักขระพิเศษ  ' (single quote) และ \" (double quote)"
                        }
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <select
                      id="IDCardQuestion"
                      name="IDCardQuestion"
                      className="custom-select"
                      defaultValue="0"
                      onChange={(e) => {
                        setIDCardHRI(e.currentTarget.value);
                        handleChange(e);
                      }}
                    >
                      <option value="0">
                        {language === "Thai"
                          ? "คำถามยืนยันตัวตน"
                          : "Vertify Question"}
                      </option>
                      {QuestionSelect}
                    </select>
                    <span className="error d-block">
                      {errors["IDCardQuestion"]}
                    </span>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="input-addon">
                      {/* <span className="require">*</span> */}
                    </div>
                    <input
                      type="text"
                      id="IDCardAnswer"
                      name="IDCardAnswer"
                      placeholder={language === "Thai" ? "คำตอบ" : "Answer"}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="error d-block">
                      {errors["IDCardAnswer"]}
                    </span>
                  </div>
                  <div className="controlBtn mt-5 w-100">
                    <input
                      type="submit"
                      className="fadeIn fourth btnSecondary"
                      value={language === "Thai" ? "ตกลง" : "Sign Up"}
                    />
                    <input
                      type="submit"
                      className="fadeIn fourth btnPimary"
                      value={language === "Thai" ? "ยกเลิก" : "Cancel"}
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/");
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row">
                <div className="col-12">
                  <div className="formContent box_forgotpassword">
                    <h1 className="title-header">
                      <span className="orang underline">
                        {language === "Thai" ? "ลงทะเบียน" : "Register"}
                      </span>
                    </h1>
                    <form
                      className="row form-center"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (handleValidation("PASSPORT")) {
                          Register("PASSPORT");
                        }
                      }}
                    >
                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="text"
                          id="PassportID"
                          name="PassportID"
                          placeholder={
                            language === "Thai"
                              ? "เลขที่หนังสือเดินทาง"
                              : "Passport Number"
                          }
                          autoComplete="off"
                          maxLength="13"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportID"]}
                        </span>
                      </div>

                      {/* <div className="col-md-6 col-xs-12">
                        <div className="row">
                          <div
                            className="mr-2 text-center"
                            style={{
                              marginTop: auto,
                              marginTop: -15 + "px",
                              marginLeft: 10,
                              color: "#999",
                            }}
                          >
                            {language === "Thai" ? "วันเกิด" : "Birthday"}
                          </div>
                          <div
                            className="no-underline"
                            style={{
                              width: "100%",
                              paddingLeft: 15 + "px",
                            }}
                          >
                            {language === "Thai" && (
                              <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                locale={"th"}
                              >
                                <DatePicker
                                  openTo="year"
                                  views={["year", "month", "date"]}
                                  value={birthDate}
                                  format="D MMMM YYYY"
                                  pickerHeaderFormat="ddd D MMM"
                                  yearOffset={543}
                                  maxDate={new Date()}
                                  onChange={SetBirthDate}
                                  name="birthDate"
                                  id="birthDate"
                                />
                                <div className="input-addon">
                                  <span className="require">*</span>
                                </div>
                              </MuiPickersUtilsProvider>
                            )}
                            {language !== "Thai" && (
                              <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                locale={"en"}
                              >
                                <DatePicker
                                  openTo="year"
                                  views={["year", "month", "date"]}
                                  value={birthDate}
                                  format="D MMMM YYYY"
                                  pickerHeaderFormat="ddd D MMM"
                                  maxDate={new Date()}
                                  onChange={SetBirthDate}
                                  name="birthDate"
                                  id="birthDate"
                                />
                                <div className="input-addon">
                                  <span className="require">*</span>
                                </div>
                              </MuiPickersUtilsProvider>
                            )}
                          </div>
                        </div>
                        <SError error={errors["birthDate"]} />
                      </div> */}

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="text"
                          id="PassportFirstName"
                          name="PassportFirstName"
                          className="require"
                          placeholder={language === "Thai" ? "ชื่อ" : "Name"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportFirstName"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="text"
                          id="PassportLastName"
                          name="PassportLastName"
                          placeholder={
                            language === "Thai" ? "นามสกุล" : "Surname"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportLastName"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <select
                          id="PassportGender"
                          name="PassportGender"
                          className="custom-select"
                          defaultValue=""
                          onChange={(e) => {
                            setPassportGender(e.currentTarget.value);
                            handleChange(e);
                          }}
                        >
                          <option value="">
                            {language === "Thai" ? "เลือกเพศ" : "Select Gender"}
                          </option>
                          <option value="M" checked={passportgender === "M"}>
                            {language === "Thai" ? "ชาย" : "Male"}
                          </option>
                          <option value="F" checked={passportgender === "F"}>
                            {language === "Thai" ? "หญิง" : "Female"}
                          </option>
                          <option value="O" checked={passportgender === "O"}>
                            {language === "Thai" ? "อื่น ๆ" : "Other"}
                          </option>
                        </select>
                        <span className="error d-block">
                          {errors["PassportGender"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="text"
                          id="PassportPhone"
                          name="PassportPhone"
                          value={passportPhone}
                          placeholder={
                            language === "Thai"
                              ? "หมายเลขโทรศัพท์มือถือ"
                              : "Mobile Number"
                          }
                          maxLength={10}
                          autoComplete="off"
                          onChange={(e) => {
                            handlePhoneChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportPhone"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <select
                          id="PassportHRI"
                          name="PassportHRI"
                          className="custom-select"
                          defaultValue="0"
                          onChange={(e) => {
                            setPassportHRI(e.currentTarget.value);
                            handleChange(e);
                          }}
                        >
                          <option value="0">
                            {language === "Thai"
                              ? "ประเด็นสิทธิมนุษยชนที่สนใจ"
                              : "Human rights issues of interest"}
                          </option>
                          {TopicSelect}
                        </select>
                        <span className="error d-block">
                          {errors["PassportHRI"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="text"
                          id="PassportEmail"
                          name="PassportEmail"
                          placeholder={language === "Thai" ? "อีเมล" : "Email"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportEmail"]}
                        </span>
                      </div>

                      <div
                        id="show_hide_password"
                        className="col-md-6 col-xs-12"
                      >
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="password"
                          id="PassportPassword"
                          name="PassportPassword"
                          placeholder={
                            language === "Thai" ? "รหัสผ่าน" : "Password"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            handlePasswordChange(e);
                          }}
                        />
                        <div className="input-addon">
                          <a href="/">
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                            ></i>{" "}
                          </a>
                        </div>
                        <span className="error d-block">
                          {errors["PassportPassword"]}
                        </span>
                      </div>

                      <div
                        id="show_hide_password"
                        className="col-md-6 col-xs-12"
                      >
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="password"
                          id="PassportConfirmPassword"
                          name="PassportConfirmPassword"
                          placeholder={
                            language === "Thai"
                              ? "ยืนยันรหัสผ่าน"
                              : "Confirm Password"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            handlePasswordChange(e);
                          }}
                        />
                        <div className="input-addon">
                          <a href="/">
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                            ></i>{" "}
                          </a>
                        </div>
                        <span className="error d-block">
                          {errors["PassportConfirmPassword"]}
                        </span>
                      </div>
                      <div className="col-md-6 col-xs-12 text-center">
                        <ul
                          className="listPassword"
                          style={{
                            listStyleType: "square",
                            textAlign: "left",
                            width: "420px",
                            margin: "0 auto",
                          }}
                        >
                          การตั้งรหัสผ่าน ต้องมีความยาวอย่างน้อย 8
                          ตัวอักษรหรือมากกว่านั้น
                          <li>- ตัวอักษรตัวเล็ก (a-z) อย่างน้อย 1 ตัว</li>
                          <li>- ตัวอักษรตัวใหญ่ (A-Z) อย่างน้อย 1 ตัว</li>
                          <li>- ตัวเลข (0-9) อย่างน้อย 1 ตัว</li>
                          <li>
                            {
                              "- เครื่องหมายหรืออักขระพิเศษ  (!@#$%^&*()_+|~-={}[]:;<>?,./) อย่างน้อย 1 ตัว"
                            }
                          </li>
                          <li>
                            {
                              "- ห้ามมีอักขระพิเศษ  ' (single quote) และ \" (double quote)"
                            }
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <select
                          id="PassportQuestion"
                          name="PassportQuestion"
                          className="custom-select"
                          defaultValue="0"
                          onChange={(e) => {
                            setIDCardHRI(e.currentTarget.value);
                            handleChange(e);
                          }}
                        >
                          <option value="0">
                            {language === "Thai"
                              ? "คำถามยืนยันตัวตน"
                              : "Vertify Question"}
                          </option>
                          {QuestionSelect}
                        </select>
                        <span className="error d-block">
                          {errors["PassportQuestion"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="input-addon">
                          {/* <span className="require">*</span> */}
                        </div>
                        <input
                          type="text"
                          id="PassportAnswer"
                          name="PassportAnswer"
                          placeholder={language === "Thai" ? "คำตอบ" : "Answer"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportAnswer"]}
                        </span>
                      </div>
                      <div className="controlBtn mt-5 w-100">
                        <input
                          type="submit"
                          className="fadeIn fourth btnSecondary"
                          value={language === "Thai" ? "ลงทะเบียน" : "Sign Up"}
                        />
                        <input
                          type="submit"
                          className="fadeIn fourth btnPimary"
                          value={language === "Thai" ? "ยกเลิก" : "Cancel"}
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/");
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
